import * as React from 'react'
import classNames from 'classnames'
import * as styles from './blockList.module.scss'
import { WithChildren, WithClassName } from '../../types'

const BlockList = ({ className, children }: WithClassName & WithChildren) => {
  return (
    <div className={classNames(className, styles.blockList)}>{children}</div>
  )
}

export default React.memo(BlockList)

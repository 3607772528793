import { graphql, HeadProps, PageProps } from 'gatsby'
import * as React from 'react'

import BlockList from '../components/blockList/blockList'
import Layout from '../components/layout'
import MiscPage from '../components/miscPage/miscPage'
import Seo from '../components/seo'
import WidthLimiter from '../components/widthLimiter/widthLimiter'
import { AllMarkdownRemark } from '../types'

const DataProtectionPage = ({ data }: PageProps<AllMarkdownRemark>) => {
  const entry = data.allMarkdownRemark.nodes[0]

  return (
    <Layout>
      <BlockList>
        <WidthLimiter>
          <MiscPage entry={entry} />
        </WidthLimiter>
      </BlockList>
    </Layout>
  )
}

export const Head = ({ location }: HeadProps) => (
  <Seo
    title="Datenschutzerklärung"
    description="Weshalb wir Daten sammeln."
    pathname={location.pathname}
    noindex
  />
)

export default DataProtectionPage

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      filter: {
        frontmatter: {
          template: { eq: "misc" }
          key: { eq: "data-protection" }
        }
      }
    ) {
      nodes {
        fields {
          slug
        }
        html
        frontmatter {
          title
        }
      }
    }
  }
`

import './miscPage.scss'
import * as React from 'react'
import { MarkdownRemarkNode } from '../../types'

type MiscPageProps = {
  entry: MarkdownRemarkNode
}

const MiscPage = ({ entry }: MiscPageProps) => {
  return (
    <div className="misc-page">
      <div key={entry.fields.slug}>
        <article dangerouslySetInnerHTML={{ __html: entry.html }} />
      </div>
    </div>
  )
}
export default MiscPage
